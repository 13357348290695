import React from "react";
import FooterQuotation from "./FooterQuotation";
import twitterlogo from "../assets/icons/icons8-twitter.svg";
import { useEffect } from "react";
import { footerAboutApi, footerContentApi } from "./apiServices";
import { useDispatch, useSelector } from "react-redux";
import { getBrandsApi } from "../Products/apiServices";

import { useNavigate, useLocation } from "react-router-dom";
import { contactBasicApi } from "./apiServices";
import { setFilter } from "../slices/productsSlice";
import {
  contactShowroomApi,
  contactBranchesApi,
  contactHeadQuartersApi,
} from "../components/apiServices";

import { socailMediaLinksApi } from "../components/apiServices";

const Footer = () => {
  const {
    contactShowroom,
    contactBranches,
    contactHeadquarters,
    socialMediaLinks,
  } = useSelector((state) => state.sharedState);
  const { allSubCategory, brands, footerAbouts, contactHeader } = useSelector(
    (state) => state.sharedState
  );
  const { pathname, search } = useLocation();
  const fullPath = `${pathname}${search}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(footerContentApi());
    dispatch(getBrandsApi());
    dispatch(contactBasicApi());
    dispatch(socailMediaLinksApi());
    dispatch(footerAboutApi());
    dispatch(contactHeadQuartersApi());
    dispatch(contactBranchesApi());
    dispatch(contactShowroomApi());
  }, []);

  const handleOrder = () => {
    const message =
      contactHeader && contactHeader.wa_message ? contactHeader.wa_message : "";
    const phoneNumber =
      contactHeader && contactHeader.wa_number ? contactHeader.wa_number : "";
    let url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  const handleSubCategory = (child, item) => {
    dispatch(
      setFilter({
        subcategory: child.id,
        subcategoryName: child.description,
        category: item.id,
        categoryName: item.name,
      })
    );
    navigate(`/products`);
  };

  const handleBrand = (brand) => {
    dispatch(setFilter({ brands: brand.id }));
    navigate(`/products?brands=${brand.id}`);
  };

  const handleCategories = (category) => {
    dispatch(setFilter({ category: category.id, categoryName: category.name }));
    navigate(`/products`);
  };

  return (
    <footer className="container-fluid footer">
      <div className="container footer-area">
        <div className="first-section">
          <FooterQuotation />
        </div>
        <div className="second-section">
          <div className="about-us">
            {footerAbouts.length > 0
              ? footerAbouts.map((footerItem, index) => (
                <div key={index}>
                  <p className="title">{footerItem && footerItem.subtitle}</p>
                  <p className="name">{footerItem && footerItem.title}</p>
                  <p className="content">
                    {footerItem && footerItem.content}
                  </p>
                </div>
              ))
              : null}

            <div className="social-icons">
              {socialMediaLinks.length > 0 ? (
                <>
                  {socialMediaLinks.map((socialItem, index) => (
                    <div key={index}>
                      <a
                        href={socialItem.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={socialItem.icon}
                          alt={socialItem.platform}
                          style={{ height: "30px", width: "30px" }}
                        />
                      </a>
                    </div>
                  ))}
                  <div>
                    <a
                      href="https://www.wa.me/"
                      onClick={() => handleOrder()}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i
                        className="fab fa-whatsapp"
                        style={{
                          color: "#E2E2E2",
                          fontSize: "20px",
                        }}
                      ></i>
                    </a>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {allSubCategory.map((item, index) => {
            return (
              <div className="categories-section" key={index}>
                <p className="title" onClick={() => handleCategories(item)}>
                  {item.name}
                </p>
                <ul>
                  {item.children.map((child, childIndex) => (
                    <li
                      onClick={() => handleSubCategory(child, item)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>{child.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
          <div className="categories-section">
            <p className="title">Brands</p>
            <ul>
              {brands.map((brand, index) => (
                <ul key={index}>
                  <li onClick={() => handleBrand(brand)}>
                    {brand.name}
                  </li>
                </ul>
              ))}
            </ul>
          </div>
        </div>
        {fullPath === "/about-us?contact-us" ? null : (
        <div className={`third-section ${pathname.includes("/about-us?contact-us") ? 'none' : ''}`}>
            <div className="row">
              <div className="top-section col-md-9">
                {/* Head Office */}
                <div className="content-wrapper">
                  <p className="title">Head Office</p>
                  <p style={{ color: "#e2e2e2" }}>
                    {contactHeadquarters.address}
                  </p>
                  <a
                    style={{ color: "#e2e2e2", textDecoration: "none" }}
                    href={`tel:${contactHeadquarters.phone_1} `}
                  >
                    {contactHeadquarters.phone_1}
                  </a>{" "}
                  |
                  <a
                    style={{ color: "#e2e2e2", textDecoration: "none" }}
                    href={`tel:${contactHeadquarters.phone_2} `}
                  >
                    {contactHeadquarters.phone_2}
                  </a>
                </div>

                {/* Branches */}
                <div className="branch">
                  {contactBranches.map((branch) => (
                    <div className="content-wrapper" key={branch.id}>
                      <p className="title">{branch.branch_name}</p>
                      <p className="content">
                        {branch.address}
                        <a
                          style={{ color: "#e2e2e2", textDecoration: "none" }}
                          href={`tel:${branch.phone_1} `}
                        >
                          {branch.phone_1}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>

                {/* Showroom */}
                <div className="content-wrapper">
                  <p className="title">Showroom</p>
                  {contactShowroom.map((showroom) => (
                    <div key={showroom.id} className="branch-one">
                      <p className="content">{showroom.address}</p>
                      <p className="content">
                        <a
                          style={{ color: "#e2e2e2", textDecoration: "none" }}
                          href={`tel:${showroom.phone_1}`}
                        >
                          {showroom.phone_1}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Reach Us */}
            <div className="row">
              <div className="bottom-section col-lg-9">
                <div className="content-wrapper">
                  <p className="title">Reach Us</p>
                  <a
                    className="content"
                    style={{ textDecoration: "none" }}
                    href={`mailto:${contactHeadquarters.email}`}
                  >
                    {contactHeadquarters.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="fourth-section">
          <p>© 2023. All Rights Reserved.</p>
          <p>
            Designed by :{" "}
            <a href="https://theneuch.com/" target="_blank" rel="noreferrer">
              Chetan Kumar Neupane
            </a>
          </p>
          <p>
            Powered by{" "}
            <a href="https://www.tecfuge.com/" target="_blank" rel="noreferrer">
              Tecfuge
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
