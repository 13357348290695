import React from 'react'
import { useNavigate } from 'react-router-dom'

const MobileBrands = ({brands,content }) => {
  const navigate = useNavigate()


  return (
    <div className='brands-section container-fluid'>
      <div className='brand container'>
        <div className='top-section'>
          <h1>{content?.title ? content.title : ''}</h1>
        </div>
        <div className='bottom-section'>
          {
            brands.map((brand, index) => (
              <div className='logo' onClick={() => navigate(`/products?brands=${brand.id}`)}>
                <img src={brand.image} alt="logo" />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default MobileBrands