import { globalGetService } from "../../utils/globalApiServices";
import {
  applicationSuccess,
  brandsSuccess,
  categoriesSuccess,
  loadingOff,
  loadingOn,
} from "../../slices/sharedSlice";
import {
  bannerSucess,
  blogSuccess,
  heroSlidesSucces,
  homeSuccess,
  testimonialsSucess,
} from "../../slices/homeSlice";

export const getBrandsApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/brands").then((response) => {
        return response.data.Data;
      });
      dispatch(loadingOff());
      dispatch(brandsSuccess(data));
    } catch (error) {
      dispatch(loadingOff());
    }
  };
};
export const getCategoriesApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/categories").then((response) => {
        return response.data.Data;
      });
      dispatch(loadingOff());
      dispatch(categoriesSuccess(data));
    } catch (error) {
      
      dispatch(loadingOff());
    }
  };
};
export const getHeroSlidesApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/banner-slides").then(
        (response) => {
          return response.data.banner_slides;
        }
      );
      dispatch(loadingOff());
      dispatch(heroSlidesSucces(data));
    } catch (error) {
      
      dispatch(loadingOff());
    }
  };
};
export const getTestimonialsApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/testimonial-slides").then(
        (response) => {
          return response.data.data;
        }
      );
      dispatch(loadingOff());
      dispatch(testimonialsSucess(data));
    } catch (error) {
      
      dispatch(loadingOff());
    }
  };
};

export const getApplicationAreasApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/product-application-areas").then(
        (response) => {
          return response.data.data;
        }
      );
      dispatch(loadingOff());
      dispatch(applicationSuccess(data));
    } catch (error) {
      
      dispatch(loadingOff());
    }
  };
};
export const getBannerApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/posters").then((response) => {
        return response.data;
      });
      dispatch(loadingOff());
      dispatch(bannerSucess(data));
    } catch (error) {
      
      dispatch(loadingOff());
    }
  };
};
export const getHomeApi = (setData) => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/home-page-content").then(
        (response) => {
          return response.data.data;
        }
      );
      dispatch(loadingOff());
      dispatch(homeSuccess(data));
    } catch (error) {
      
      dispatch(loadingOff());
    }
  };
};

export const getBlogApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn());
      const data = await globalGetService("api/blogs").then((response) => {
        dispatch(loadingOff());
        return response.data.data;
      });
      dispatch(blogSuccess(data));
    } catch (error) {
      
    }
  };
};
