import React from 'react'
import { testimonialRectangle1, testimonialRectangle2, user } from '../../assets';
import TabTestimonial from './TabTestimonial';
import { useMediaQuery } from 'react-responsive';
import { Fade } from 'react-reveal'

const Testimonials = ({ testimonials, content }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <Fade bottom>
        <section className='testimonials-section container-fluid'>
          <div className='container testimonials'>
            <div className='top-section'>
              <h1>{content?.title ? content.title : ''}</h1>
              <div className='top-section-content'>
                <p>{content?.description ? content.description : ''}</p>
              </div>
            </div>
            {isTablet ? (
              <TabTestimonial />
            ) :
              (

                <div className='bottom-section'>
                  <div className='rectangular-container'>
                    {testimonials.slice(-2).map((testimonial, index) => (
                      <div style={{ alignItems: index === 1 ? 'flex-start' : 'flex-end' }}>
                        <div className='rectangular-container-item' key={index}  >
                          <div>
                            <img style={{ transform: index === 1 ? 'rotateY(180deg)' : "" }} src={testimonialRectangle1} alt="" height='330px' />
                            <p className='testimonial-content'>{testimonial.testimonial_content}</p>
                          </div>
                        </div>
                        <div className='testimonial-user-container'>

                          <div className='user' key={index} style={{ flexDirection: (index + 1) % 2 === 0 ? 'row-reverse' : 'row', marginLeft: index === 1 ? '-2.5%' : '', paddingRight: index === 0 ? '21px' : '' }}>
                            <div className='user-content'>
                              <h3>{testimonial.author}</h3>
                              <p style={{ textAlign: (index === 0 && testimonial.job_role) ? 'right' : 'left' }}>
                                {testimonial.job_role}
                              </p>
                            </div>
                            <img src={testimonial.author_img} alt='' />
                          </div>

                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </section >
      </Fade>
    </>
  )
}

export default Testimonials