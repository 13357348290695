import React, { useState, useEffect, useRef } from 'react';
import '../styles/popup.scss';
import thankyou from '../assets/thankyou.png';

const Popup = ({ message, onClose }) => {
  const popupRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="popup" ref={popupRef}>
      <div className="popup-content">
        <div className="popup-message">
          <img src={thankyou} alt='success' style={{ marginBottom: '10px' }} />
          <h3>Sunrise Oasis Lighting LLC</h3>
          {message ? (
            <p>{message}</p>
          ) : (
            <>
              <p>Thank you For Sending Quotation</p>
              <h6>We will contact you soon!!</h6>
            </>
          )}
        </div>
        <div className="popup-button-container">
          <button className="popup-close-button" onClick={onClose}>
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
