import React from 'react'
import { aboutImage1, aboutImage2, aboutImage3, aboutImage4 } from '../../assets'
import { useMediaQuery } from 'react-responsive';
import { image5, aboutUsArrow } from '../../assets/mobile'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom';




const About = ({ content }) => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery({ query: "(max-width: 991px)" });
  return (
    <>
      <section className='about-us-section container-fluid'>
        {isTablet ? (
          <div className='mobile-about-us-section container-fluid container'>
            <div className='about-us section row'>
              <div className='image-container col-5'>
                <img src={image5} alt="aboutus" />
              </div>
              <div className='about-us-container col-7 '>
                <h4>{content?.title ? content.title : ''}</h4>
                <p>
                {content?.description ?content.description : ''}
                </p>
                <div className="learn-more">
                  <p onClick={() => navigate('/about-us?messages')}>Learn More</p>
                  <img src={aboutUsArrow} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
        ) : (

          <div className='container about-us'>
            <Fade bottom>
              <div className='row'>
                <div class="grid-container   col-md-6">
                  <div>
                    <img class='grid-item grid-item-1' src={aboutImage1} alt='' />
                  </div>
                  <div>
                    <img class='grid-item grid-item-2' src={aboutImage2} alt='' />
                  </div>
                  <div>
                    <img class='grid-item grid-item-3' src={aboutImage3} alt='' />
                  </div>
                  <div>
                    <img class='grid-item grid-item-4' src={aboutImage4} alt='' />
                  </div>
                </div>
                <div className='contents col-md-6'>
                  <div className="contents-container">
                    <div>
                      <h1>{content?.title ? content.title : ''}</h1>
                    </div>
                    <div>
                      <p>
                      {content?.description ?content.description : ''}
                       </p>
                    </div>
                    <div >
                      <button className='btn btn-outline-light' onClick={() => navigate('/about-us?messages')}>Learn More About Us</button>
                    </div>

                  </div>
                </div>

              </div>
            </Fade>
          </div>


        )}
      </section>
    </>


  )
}

export default About