import React from "react";
import { image5, aboutUsArrow } from "../assets/mobile";
import "../styles/mobileAbout.scss";
import { useNavigate } from "react-router";

const MobileAboutUs = ({ content }) => {
  const navigate = useNavigate()
  return (
    <div className="mobile-about-us-section container-fluid container">
      <div className="about-us section row">
        <div className="image-container col-5">
          <img src={image5} alt="aboutus" />
        </div>
        <div className='about-us-container col-7 '>
          <h4>{content?.title ? content.title : ''}</h4>
          <p>
            {content?.description ? content.description.substring(0, 100) : ''}
            {content?.description && content.description.length > 100 ? '...' : ''}
          </p>
          {content?.description && content.description.length > 100 && (
            <div className="learn-more">
               <p  onClick={() => navigate('/about-us?messages')}>Learn More</p>
              <img src={aboutUsArrow} alt="arrow" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileAboutUs;
