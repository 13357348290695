import React from 'react'
import '../styles/shopMegaMenu.scss'
import { Fade } from 'react-reveal'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


function ShopMegaMenu(megamenu) {
    const { shopMegaMenu: { left, right = {} } } = useSelector(state => state.sharedState)
    const menuList = [{

        head: 'Indoor Lights',
        content: 'Indoor lights can be used to create a cozy atmosphere in your home.',
        bottomHead: 'Know More About Indoor Lights'

    }]
    const array = new Array(6).fill(menuList);
    return (

        <div className="container-fluid">
            <Fade bottom duration="1000" distance="50px">
                <div className="shop-mega-menu" onMouseLeave={megamenu.mouseShopLeave}>
                    <div className="megamenu-part-one">
                        <div className="megaMenu-head">
                            <h1>{left?.leftside_title}</h1>
                            <p>{left?.leftside_description}</p>
                            <Link to="/products"><button>Browse all the categories..</button></Link>
                            <img src={left?.leftside_img} alt="" />
                        </div>
                    </div>
                    <div className="line  col-md-2"></div>
                    <div className="megaMenu-part-two">
                        <div className="megaMenu-head">
                            <h1>{left?.rightside_title}</h1>
                            <p>{left?.rightside_description}</p>
                            <div className='row mega-menu'>
                                {right?.slice(0, 6).map((item, index) => {
                                    return (
                                        <div className='col-md-4' key={index}>
                                            <div className=' menu-container'>
                                                <img src={item?.image} alt='menu' />
                                                <div className='menu-content'>
                                                    <h3>{item?.title}</h3>
                                                    <p>{item?.description}</p>
                                                    <a href={item?.link} target="_blank" rel="noopener noreferrer">
                                                        <h5>See more</h5>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>


    )
}

export default ShopMegaMenu