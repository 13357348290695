import React from 'react'
import '../../styles/tabTestimonial.scss'
import {  user } from '../../assets';

function TabTestimonial() {
  return (
   <div className="container-fluid">
    <div className="container ">
        
        <div className="tab-testimonial-section">
      
            <div className="testimonial ">
                <p>Don't just take our word for it - see what our satisfied customers have to say about our lighting fixtures! From exceptional quality to unbeatable style, our fixtures have made a big impact in homes across the country. Read our testimonials and see for yourself!"</p>
                <div className='test-user-img'>
                    <img src={user} alt="" />
                    <div className='test-user-details'>
                        <h3>Santosh Khan</h3>
                        <h6>Sale Executive</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>
  )
}

export default TabTestimonial