import React from 'react'
import {  line67 } from '../../assets';
import { Fade } from 'react-reveal'
import { useSelector } from 'react-redux';


const Blogs = ({ content }) => {
  const { blog } = useSelector(state => state.homeState)
  const lastFourBlogs = blog.slice(-4);

  return (
    <section className='blogs-section container-fluid'>
      <div className='container blogs'>
        <Fade bottom>
          <div className='top-section'>
            <h1>{content?.title ? content.title : ''}</h1>
            <div className='top-section-content'>
              <p>{content?.description ? content.description : ''}</p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className='bottom-section'>
            <div className='blogs'>
              {
                lastFourBlogs.map((blog, index) =>
                  <div className='blog' key={index}>
                  <div className='blog-img'>
                    <img src={blog.image} alt="" />
                    </div>
                    <h4>{blog.title}</h4>
                    <caption style={{wordBreak:'break-all'}}>
                      {blog.description && blog.description.length < 100 ?
                        blog.description : `${blog.description.substring(0, 100)}...`}
                    </caption>

                    <div>
                      <p>Read Articles</p>
                      <img className='read_img'src={line67} alt="" />
                    </div>
                  </div>)
              }
            </div>

          </div>
        </Fade>
      </div>
    </section>
  )
}

export default Blogs