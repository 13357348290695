import React from 'react'
import QuotationModal from '../../components/QuotationModal'


const MobileQuotation = () => {
  return (
    <section className='mobile-quotation-section container-fluid'>
      <div className='quotation container-fluid'>
        <h4>Let's talk about<br />
          your project
        </h4>
        <QuotationModal mode="general" />
      </div>
    </section>
  )
}

export default MobileQuotation