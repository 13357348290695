import React from 'react'
import { useDispatch } from 'react-redux';
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom';
import { setFilter } from '../../slices/productsSlice';


const ProductCategories = ({ categories, content }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleCategories = (category) => {
    dispatch(setFilter({ category: category.id , categoryName : category.name }))
    navigate(`/products`)
  }
  return (
    <section className='product-categories-section container-fluid'>
      <div className='container categories'>
        <Fade bottom>
          <div className='top-section'>
            <h1>{content?.title ? content.title : ''}</h1>
            <div className='top-section-content'>
              <p>{content?.description ? content.description : ''}</p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className='bottom-section'>
            <div className="box-wrapper  " >
              {
                categories.map((category, index) => {
                  const startingIndexOfLastRow = categories.length - (categories.length % 8);
                  const isLastItem = index === categories.length - 1;
                  const isLastRow = Math.floor(index / 8) === Math.floor((categories.length - 1) / 8);
                  return (
                    <div className="custom-box box" style={{ flexBasis: '12.5%' }} key={index} onClick={() => handleCategories(category)} >
                      <div className='custom-box-bg text' >
                        <p>{category.name}</p>
                      </div>
                      <div className='side-line' style={isLastItem ? { display: 'none' } : {}} ></div>
                      <div className='bottom-line' style={isLastRow ? { display: 'none' } : {}}></div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default ProductCategories