import React, { useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import '../authorisation.scss';
import { loginApi, registerApi } from '../apiServices';
import { useDispatch, useSelector } from 'react-redux';
import { authModalToggle, setPopupMessage, setShowPopUp, setThankYou } from '../../slices/sharedSlice';
import { setGlobalCookie } from '../../utils';



const LoginRegister = ({ mobileNavigatorCart, mobileNavigatorProfile }) => {

  const dispatch = useDispatch();
  const { authModal } = useSelector(state => state.sharedState);
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('Login');

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passError, setPassError] = useState("");

  const authorisationFn = () => {
    // Reset previous validation errors
    setNameError('');
    setEmailError('');
    setPasswordError('');

    const formData = {
      name: mode === 'Register' ? nameRef.current.value : null,
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    let isValid = true;

    // Validation rules
    if (mode === 'Register') {
      if (!formData.name) {
        setNameError('Name is required');
        isValid = false;
      }
    }

    if (!formData.email || !validateEmail(formData.email)) {
      setEmailError('Valid email is required');
      isValid = false;
    }

    if (!formData.password || formData.password.length < 8) {
      if (mode === "Register") {
        setPasswordError('Password must be at least 8 characters long');
      }
      isValid = false;
    }

    if (isValid) {
      if (mode === 'Register') {
        // Call registration API
        registerApi(formData)
          .then(response => {
            if (response.data.statusCode < 300) {
              setGlobalCookie('token', JSON.stringify(response.data.token));
              setGlobalCookie('user', JSON.stringify(response.data.user));
              dispatch(setPopupMessage('You have successfully registered'));
              dispatch(setShowPopUp(true));
              closeModal();
              setTimeout(() => {
                window.location.reload(false)
              }, 1000);
            } else {
              setPassError(response.data.message)
            }
          })
      } else {
        // Call login API
        loginApi(formData)
          .then(response => {
            if (response.data.statusCode < 300) {
              setGlobalCookie('token', JSON.stringify(response.data.token));
              setGlobalCookie('user', JSON.stringify(response.data.user));
              dispatch(setPopupMessage('You have successfully logged in'));
              dispatch(setThankYou(''))
              dispatch(setShowPopUp(true));
              closeModal();
              setTimeout(() => {
                window.location.reload(false)
              }, 1000);

            } else {
              setPassError(response.data.message)
            }
          })
      }
    }
  };

  // Add this function for email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const closeModal = () => {
    dispatch(authModalToggle());
  };

  return (
    <div>
      {mobileNavigatorCart ?
        <div className='mobile-navigator-item' onClick={() => dispatch(authModalToggle())}>
          <i className='fa fa-shopping-cart'></i>
        </div> :
        mobileNavigatorProfile ?
          <div className='mobile-navigator-item' onClick={() => dispatch(authModalToggle())}>
            <i className='fa fa-user'></i>
          </div> :
          <button
            type="button"
            className="btn btn-outline-light btn-pad"
            onClick={() => dispatch(authModalToggle())}
          >
            Login/Register
          </button>
      }

      <Modal show={authModal} onHide={closeModal} className="auth-modal" centered>
        <ModalHeader closeButton style={{ backgroundColor: "#393d46", color: '#fff' }}>
          <ModalTitle>{mode}</ModalTitle>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#393d46", }}>
          {mode === 'Register' && (
            <div className="form-group" >
              <label htmlFor="prevautofillName">Name:</label>
              <input
                ref={nameRef}
                type="text"
                className="form-control"
                placeholder="Enter name"
                id="prevautofillName"
                autoComplete="off"
              />
              <span className="error">{nameError}</span>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="prevautofillEmail">Email address:</label>
            <input
              ref={emailRef}
              type="email"
              className="form-control"
              placeholder="Enter email"
              id="prevautofillEmail"
              autoComplete="off"
            />
            <span className="error">{emailError}</span>
          </div>
          <div className="form-group">
            <label htmlFor="prevautofillPassword">Password:</label>
            <input
              ref={passwordRef}
              type={show ? 'text' : 'password'}
              className="form-control"
              placeholder="Enter password"
              id="prevautofillPassword"
              autoComplete="off"
            />
            <span className="error">{passwordError}</span>
            {show ? (
              <i className="fas fa-eye-slash" onClick={() => setShow(false)}></i>
            ) : (
              <i className="fas fa-eye" onClick={() => setShow(true)}></i>
            )}
          </div>
          <span style={{ color: 'tomato', fontSize: '12px', textTransform: 'capitalize' }}>{passError}</span>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#393d46", color: '#fff' }}>
          <div className="action-wrapper">
            {mode === 'Register' ? (
              <p>
                Already a user? <span onClick={() => setMode('Login')}> Login</span>
              </p>
            ) : (
              <p>
                Not a user? <span onClick={() => setMode('Register')}> Register</span>
              </p>
            )}
            <div className="auth-cta">
              <button className="btn-quotation box" style={{ border: "1px solid var(--Secondary-Color-2, #E2E2E2)", width: '100px', paddingTop: '2%', paddingBottom: '2%' }} onClick={authorisationFn}>
                <span className='text' style={{ textDecoration: 'none', width: '100px', }}>{mode}</span>
              </button>
              <button className="btn-quotation box" style={{ border: "1px solid var(--Secondary-Color-2, #E2E2E2)", width: '100px', paddingTop: '2%', paddingBottom: '2%' }} onClick={closeModal}> <span className='text' style={{
                textDecoration: 'none', width: '100px'
              }}> Close</span> </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>



    </div>
  );
};

export default LoginRegister;
