import React, { useEffect } from "react";
import "../styles/mobile-footer.scss";
// import QuotationModal from '../../components/QuotationModal'
import QuotationModal from "../components/QuotationModal.jsx";

import {
  contactBranchesApi,
  contactHeadQuartersApi,
  contactShowroomApi,
} from "./apiServices";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { setFilter } from "../slices/productsSlice.js";
import { socailMediaLinksApi } from "../components/apiServices";

const MobileFooter = () => {
  const { contactShowroom, contactBranches, contactHeadquarters } = useSelector(
    (state) => state.sharedState
  );
  const { allSubCategory, brands, socialMediaLinks, contactHeader } =
    useSelector((state) => state.sharedState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOrder = () => {
    const message =
      contactHeader && contactHeader.wa_message ? contactHeader.wa_message : "";
    const phoneNumber =
      contactHeader && contactHeader.wa_number ? contactHeader.wa_number : "";
    let url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    dispatch(socailMediaLinksApi());
    dispatch(contactHeadQuartersApi());
    dispatch(contactBranchesApi());
    dispatch(contactShowroomApi());
  }, []);

  const handleSubCategory = (child, item) => {
    dispatch(
      setFilter({
        subcategory: child.id,
        subcategoryName: child.description,
        category: item.id,
        categoryName: item.name,
      })
    );
    navigate(`/products`);
  };
  const handleCategories = (category) => {
    dispatch(setFilter({ category: category.id, categoryName: category.name }));
    navigate(`/products`);
  };

  // const brands = ['Tecno-light', 'Giomilano', 'ArtDna', 'Echips', 'Ltech']
  return (
    <footer className="mobile-footer-section container-fluid">
      <div className="container mobile-footer">
        <div className="first-section">
          <div class="quotation-box">
            <div class="quotation-box-title">
              <h1>
                Let’s talk about <br />
                your project.
              </h1>
            </div>
            <div class="quotation-box-cta">
              <QuotationModal mode="general" />
            </div>
          </div>
        </div>
        <div className="second-section">
          <div className="left-part">
            <div className="head-office">
              <h4>Head Office</h4>
              <p className="content">
              <a
                style={{ color: "#fff", textDecoration: "none" }}
                href={`tel:${contactHeadquarters.phone_1} `}
              >
                {" "}
                {contactHeadquarters.phone_1}
              </a>{" "}
              <br />{" "}</p>
              <p>
              <a
                style={{ color: "#fff", textDecoration: "none" }}
                href={`tel:${contactHeadquarters.phone_2} `}
              >
                {" "}
                {contactHeadquarters.phone_2}
              </a>
              </p>
              <p className="content_contact">{contactHeadquarters?.address}</p>
              <p className="content_email">
              <a
                style={{ color: "#fff", textDecoration: "none" }}
                href={`mailto:${contactHeadquarters.email}`}
              >
                {contactHeadquarters.email}
              </a></p>

            </div>
            <div className="branch_left">
              {contactBranches.map((branch) => (
                <div className="left-part">
                  <h4>{branch.branch_name}</h4>
                  <p>{branch.address}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="right-part">
            <div className="showrooms">
              <h4>Showrooms</h4>
              {contactShowroom.map((showroom) => (
                <div key={showroom.id} className="branch-one">
                  <p className="content">{showroom.address}</p>
                  <p className="content">
                    <a
                      style={{ color: "#e2e2e2", textDecoration: "none" }}
                      href={`tel:${showroom.phone_1}`}
                    >
                      {showroom.phone_1}
                    </a>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="third-section"></div>
        <div className="fourth-section">
          <div className="categories-grid">
            {allSubCategory.map((item, index) => (
              <div className="categories-section brands" key={index}>
                <p className="title" onClick={() => handleCategories(item)}>
                  {item.name}
                </p>
                <ul>
                  {item.children.map((child, childIndex) => (
                    <ul key={childIndex}>
                      <Link to={`/products?subcategory=${child.id}`}>
                        <li onClick={() => handleSubCategory(child, item)}>
                          {child.name}
                        </li>
                      </Link>
                    </ul>
                  ))}
                </ul>
              </div>
            ))}
            <div className="categories-section brands">
              <p className="title">Brands</p>
              <ul>
                {brands.map((brand, index) => (
                  <ul key={index}>
                    <li
                      onClick={() => navigate(`/products?brands=${brand.id}`)}
                    >
                      {brand.name}
                    </li>
                  </ul>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="fifth-section">
          <div className="social-icons">
            {socialMediaLinks.length > 0 ? (
              <>
                {socialMediaLinks.map((socialItem, index) => (
                  <div key={index}>
                    <a href={socialItem.link} target="_blank" rel="noreferrer">
                      <img
                        src={socialItem.icon}
                        alt={socialItem.platform}
                        style={{ height: "30px", width: "30px" }}
                      />
                    </a>
                  </div>
                ))}

                {/* WhatsApp icon outside the loop */}
                <div>
                  <a
                    href="https://www.wa.me/"
                    onClick={() => handleOrder()}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i
                      className="fab fa-whatsapp"
                      style={{
                        color: "#E2E2E2",
                        fontSize: "20px",
                      }}
                    ></i>
                  </a>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="sixth-section">
          <div className="copy-right">
            <p>© 2023. All Rights Reserved.</p>
          </div>
        </div>
        <div className="seventh-section ">
          <p>
            Designed by:{" "}
            <a href="https://theneuch.com/" target="_blank">
              Chetan Kumar Neupane
            </a>{" "}
          </p>
          <p>
            Powered by:{" "}
            <a href="https://www.tecfuge.com/" target="_blank">
              Tecfuge
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default MobileFooter;
