import React from 'react'
import { Fade } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFilter } from '../../slices/productsSlice'


const Brands = ({ brands, content }) => {
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const handleNavigation = (brand) => {
    dispatch(setFilter({ brands: brand.id }));
    navigate(`/products?brands=${brand.id}`);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="product-brands-section">
      <div className='container brands '>
        <Fade bottom>
          <div className='top-section' >
            <h1>{content?.title ? content.title : ''}</h1>
            <div className='top-section-content'>
              <p>{content?.description ? content.description : ''}</p>
            </div>
          </div >
        </Fade>
        <Fade bottom>
          <div className='bottom-section'>
            <div className="container" >
              <div className="row box-wrapper ">
                {
                  brands.map((brand, index) => (
                    <div className="col-md-3 custom-box " key={index}>
                      <div className='custom-box-bg box' onClick={() => handleNavigation(brand)} >
                        <img src={brand.image} alt={brand.name} className='logo text' />
                      </div>
                      <div className='side-line'></div>
                      <div className='bottom-line'></div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </Fade>
        <div className='quotation-section'>
        </div>
      </div>
    </section >
  )
}

export default Brands