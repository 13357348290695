import React from 'react';
import { Fade } from 'react-reveal';
import '../styles/shopMegaMenu.scss';
import { useSelector } from 'react-redux';

const MegaMenu = (props) => {
    const { aboutUsMegaMenu } = useSelector(state => state.sharedState);


    return (
        <Fade bottom duration="500" distance="50px">
            <div className='container-fluid mega-menu-wrapper' onMouseLeave={props.mouseLeave}>
                <div className='container'>
                    <div className='row mega-menu'>
                        {Array.isArray(aboutUsMegaMenu.data) ? (
                            aboutUsMegaMenu.data.map((item, index) => (
                                <div className='col-md-3 cursor-pointer' key={index} onClick={() => window.location.assign(item.link)} >
                                    <div className='d-flex menu-container' style={{ 'border-radius': ' 5px', 'background-color': ' rgba(57, 61, 70, 0.1)' }}>
                                        <img src={item.image} alt='menu' style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        <div className='menu-content'>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                            <h5>Know More About {item.title}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default MegaMenu;