import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Carousel from 'react-bootstrap/Carousel';
import arrow from '../../assets/arrow.png';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { getSearchApi } from '../../Products/apiServices';
import { useRef } from 'react';

const Hero = ({ heroSlides }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchBoxRef = useRef(null);


  const handleSelect = (selectedIndex) => {
    if (selectedIndex < 0) {
      setActiveIndex(heroSlides.length - 1);
    } else if (selectedIndex >= heroSlides && heroSlides?.length) {
      setActiveIndex(0);
    } else {
      setActiveIndex(selectedIndex);
    }
  };
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const displayedSlides = heroSlides;

  const groupSize = 3;
  const startIndex = Math.floor(activeIndex / groupSize) * groupSize;
  const endIndex = Math.min(startIndex + groupSize, displayedSlides.length);
  const displayedThumbnails = displayedSlides.slice(startIndex, endIndex);
  const shouldHideIndicators = activeIndex >= displayedSlides.length - 2;

  useEffect(() => {
    const delay = setTimeout(() => {
      // Your code here
    }, 300); // Adjust the delay time as needed (e.g., 300 milliseconds)

    return () => clearTimeout(delay);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearch = (value) => {
    setSearchTerm(value);
    getSearchApi({ q: value }, (results) => {
      setSearchResults(results);
      // Set the active index to the first item in the search results
      setActiveIndex(results.length > 0 ? 0 : 0);
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchResults.length > 0) {
      // If Enter is pressed and there are search results, navigate to the first result
      navigate(`/product/${searchResults[0].id}`);
    }
  };



  return (
    <div className='main_hero'>
      {isTablet ? (
        <div className='tab-search'>
          <div className='tab-search-bar' ref={searchBoxRef}>
            <input type='text' placeholder='Search a product...' onChange={(e) => handleSearch(e.target.value)} />
            <button><i className="fas fa-search"></i>Search</button>
          </div>

          {searchResults.length ?
            <div className='results-list'>
              {
                searchResults.length > 0 ?
                  searchResults.map((item, index) => (
                    <div className='result' onClick={() => navigate(`/product/${item.id}`)}>
                      <div className='result-img'>
                        {
                          item && item.image && item.image?.length ?
                            <img src={item?.image[0]} alt='result-img' />
                            : null
                        }
                      </div>
                      <div className='result-content'>
                        <h4>{item.name}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  )) : null
              }
            </div> : null
          }
        </div>
      ) : (
        <div className='search-container'>
          <div className='search-bar' ref={searchBoxRef}>
            <input type='text' placeholder='Search a product...' onChange={(e) => handleSearch(e.target.value)} onKeyPress={handleKeyPress} />
            <i className="fas fa-search"></i>
          </div>
          {searchResults.length ?
            <div className='results-list'>
              {
                searchResults.length > 0 ?
                  searchResults.map((item, index) => (
                    <div className='result' onClick={() => navigate(`/product/${item.id}`)}>
                      <div className='result-img'>
                        {
                          item && item.image && item.image?.length ?
                            <img src={item?.image[0]} alt='result-img' />
                            : null
                        }
                      </div>
                      <div className='result-content'>
                        <h4>{item.name}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  )) : null
              }
            </div> : null
          }
        </div>
      )}
      <Carousel activeIndex={activeIndex} onSelect={handleSelect} controls={false} fade id="home">
        {displayedSlides.map((item, index) => (
          <Carousel.Item key={index} interval={1000}>
            <section className="container-fluid hero" style={{ background: `url(${item.banner_img})` }}>
              <Container className='contents container'>
                <div className="title">

                  <h1>{item.title}</h1>
                </div>
                <div className='sub-content'>

                  <p>{item.description}</p>
                </div>
                {item.button_text && (
                  <div className='cta'>
                    <a href={item.button_link}>
                      <button type='button' className='btn btn-outline-gold'>
                        {item.button_text}
                      </button>
                    </a>
                  </div>
                )}
              </Container>
              <div className="thumbnails">
                <div className="thumbnails-wrapper">

                  {displayedThumbnails.map((thumbnail, thumbIndex) => (
                    <div
                      key={thumbIndex}
                      className={`thumbnail-item ${activeIndex === startIndex + thumbIndex ? 'active' : ''}`}
                      style={{
                        background: `url(${thumbnail.thumbnail_img})`,
                        border: activeIndex === startIndex + thumbIndex ? '6px solid #FCAF17' : '3px solid #e2e2e2',
                        transform: activeIndex === startIndex + thumbIndex ? 'scale(1)' : 'scale(0.9)',
                      }}
                      onClick={() => setActiveIndex(startIndex + thumbIndex)}
                    >
                      <h2 className="thumbnail-title">{thumbnail.title}</h2>
                      <a href={item.button_link} className="thumbnail-subtitle"  rel="noreferrer" >Learn More</a>
                      <img src={arrow} alt="arrow" className="arrow" />
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </Carousel.Item>
        ))}

      </Carousel>
    </div>

  );
};

export default Hero;
