import { loadingOff, loadingOn } from "../slices/sharedSlice";
import { profileSuccces } from "../slices/userSlice";
import { downloadFileType } from "../utils";
import { globalExportService, globalFileUploadPutService, globalFileUploadService, globalGetService } from "../utils/globalApiServices"

export const getCategoriesApi = (setData) => {
  globalGetService("api/categories")
    .then((response) => {
      setData(response.data.Data);
    })
    .catch((error) => {
    });
}

export const getProfileApi = () => {
  return async (dispatch) => {
    try {
      dispatch(loadingOn())
      const data = await globalGetService('api/profile')
        .then(response => {
          dispatch(loadingOff())
          return response.data.data
        })
      dispatch(profileSuccces(data))
    } catch (error) {
    }
  }
}

export const updateProfileApi = (data, id) => {
  return globalFileUploadService(`/api/user-profiles/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export const downloadHistoryApi = (id) => {
  return new Promise((resolve, reject) => {
    globalExportService(`/api/pdf/${id}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      });
  })
}
