import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import homeReducer from '../slices/homeSlice';
import aboutReducer from '../slices/aboutSlice';
import productreducer from '../slices/productsSlice';
import userReducer from '../slices/userSlice';
import sharedReducer from '../slices/sharedSlice'

const reducer = combineReducers({
    homeState : homeReducer,
    aboutState : aboutReducer,
    productState: productreducer,
    userState: userReducer,
    sharedState:sharedReducer,
})


const store = configureStore({
    reducer,
    middleware:[thunk]
})

export default store;