import React from 'react'
import { banner } from '../../assets';
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSelector } from 'react-redux'

const MobileBanner = () => {
   const {banner} = useSelector(state => state.homeState)
  return (
    <Swiper
    slidesPerView={1}
    spaceBetween={10}
     className='mobile-banner-section container-fluid'>
      {
        banner && banner.map((item,index) => (
          <SwiperSlide className='banner container'>
          <img src={item.image_url} alt="" />
        </SwiperSlide>
        ))
      }
     
    </Swiper>
  )
}

export default MobileBanner